import { Navigate, createBrowserRouter } from "react-router-dom";
import PrivateIndexRoute from "components/utils/PrivateIndexRoute";
import ErrorBoundary from "pages/ErrorBoundary";

const router = createBrowserRouter([
  {
    path: "/auth",
    lazy: () => import("layouts/authLayout/AuthLayout"),
    children: [
      {
        index: true,
        element: <Navigate to="login" replace />,
      },
      {
        path: "login",
        lazy: () => import("pages/auth/Login"),
      },
      {
        path: "forgot-password",
        lazy: () => import("pages/auth/ForgotPassword"),
      },
      {
        path: "confirm-password/:id/:email",
        lazy: () => import("pages/auth/ChangePassword"),
      },
    ],
  },
  {
    path: "/",
    lazy: () => import("layouts/privateLayout/PrivateLayout"),
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <PrivateIndexRoute />,
      },
      {
        lazy: () =>
          import("layouts/privateLayout/roleBasedLayouts/SuperAdminLayout"),
        children: [
          {
            path: "clients",
            lazy: () => import("pages/clients/Clients"),
          },
          {
            path: "admins",
            lazy: () => import("pages/admins/Admins"),
          },
          {
            path: "sectors",
            lazy: () => import("pages/sectors/Sectors"),
            children:[]
          },
          {
            path: "widget-management",
            lazy: () => import("pages/widgetManagement/WidgetManagement"),
          },
          {
            path: "analytics",
            lazy: () => import("pages/analytics/Analytics"),
            children:[
              {
                index: true,
                element: <Navigate to="analyticsTab" replace />,
              },
              {
                path: "analyticsTab",
                lazy: () =>
                  import("pages/analytics/analytics/Analytics"),
              },
              {
                path: "engine",
                lazy: () =>
                  import("pages/analytics/engine/Engine")
              }
            ]
          },
        ],
      },
      {
        lazy: () =>
          import("layouts/privateLayout/roleBasedLayouts/AdminLayout"),
        children: [
          {
            path: "roles",
            lazy: () => import("pages/roles/Roles"),
          },
          {
            path: "data-management",
            lazy: () => import("pages/dataManagement/DataManagement"),
            children: [
              {
                index: true,
                element: <Navigate to="dataconfig" replace />,
              },
              {
                path: "dataconfig",
                lazy: () =>
                  import("pages/dataManagement/dataConfig/DataConfig"),
              },
              {
                path: "datasets",
                children: [
                  {
                    index: true,
                    element: <Navigate to="datasetList" replace />,
                  },
                  {
                    path: "datasetList",
                    lazy: () =>
                      import("pages/dataManagement/dataLoader/Datasets"),
                  },
                  {
                    path: "attributes/:layerId",
                    lazy: () =>
                      import(
                        "pages/dataManagement/dataLoader/components/attributeList/AttributeList"
                      ),
                  },
                ],
              },
            ],
          },
          {
            path: "widgetinstance-config",
            lazy: () =>
              import("pages/widgetInstanceConfig/WidgetInstanceConfig"),
            children: [
              {
                index: true,
                element: <Navigate to="widgetinstances" replace />,
              },
              {
                path: "widgetinstances",
                lazy: () =>
                  import(
                    "pages/widgetInstanceConfig/widgetInstances/WidgetInstances"
                  ),
              },
              {
                path: "role-widgetinstance",
                lazy: () =>
                  import(
                    "pages/widgetInstanceConfig/roleWidgetInstance/RoleWidgetInstance"
                  ),
              },
              {
                path: "role-widgetinstance/:roleId",
                lazy: () =>
                  import(
                    "pages/widgetInstanceConfig/roleWidgetInstance/RoleWidgetInstance"
                  ),
              },
            ],
          },
          {
            path: "document-verification",
            lazy: () => import("../components/documents/DocVer"),
            children: [
              {
                index: true,
                element: <Navigate to="fManager" replace />,
              },
              {
                path: "fManager",
                lazy: () =>
                  import("components/documents/tabs/fileManager/FileManager"),
              },
            ],
          },
          {
            path: "teams",
            lazy: () => import("pages/teams/Teams"),
          },
          {
            path: "db-users",
            lazy: () => import("pages/users/DbUsers"),
          },
        ],
      },
      {
        lazy: () =>
          import(
            "layouts/privateLayout/roleBasedLayouts/SuperAdminAndAdminLayout"
          ),
        children: [
          {
            path: "bi-server",
            lazy: () => import("pages/biServer/BIServer"),
          },
        ],
      },
      {
        lazy: () =>
          import("layouts/privateLayout/roleBasedLayouts/DashboardUserLayout"),
        children: [
          {
            path: "user-dashboard",
            lazy: () => import("pages/userDashboard/UserDashboard"),
          },
          {
            path: "widget-dashboard",
            lazy: () => import("pages/widgetDashboard/WidgetDashboard"),
          },
          {
            path: "widget-dashboard/:id/*",
            lazy: () => import("pages/widgetDashboard/WidgetInstance"),
          },
        ],
      },
      {
        path: "settings",
        lazy: () => import("pages/settings/Settings"),
        children: [
          {
            index: true,
            element: <Navigate to="menu" replace />,
          },
          {
            path: "menu",
            lazy: () => import("pages/settings/SettingsMenu"),
          },
          {
            path: "security",
            lazy: () => import("pages/settings/components/Security/Security"),
          },
        ],
      },
      {
        path: "account",
        lazy: () => import("pages/account/account"),
        children: [
          {
            index: true,
            element: <Navigate to="info" replace />,
          },
          {
            path: "info",
            lazy: () => import("pages/account/accountInfo"),
          },
          {
            path: "reset",
            lazy: () =>
              import("pages/account/components/ResetPassword/ResetPassword"),
          },
        ],
      },
    ],
  },
]);

export default router;
