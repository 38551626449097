import { RouterProvider } from "react-router-dom";
import "ol/ol.css";
import router from "./app/router";
import { store } from './app/store';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import './App.scss';
import { Snackbar } from "@trcsolutions/springboard-ui";
const queryClient = new QueryClient()

const App = () => {

  return (
    <>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <Snackbar />
          <RouterProvider router={router} />
        </QueryClientProvider>
      </Provider>
    </>
  );
};


export default App;

