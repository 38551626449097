
type Props = {
    width?: string;
    height?: string;
}

const BookmarkIcon = ({ width = "12", height = "12" }: Props) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 16V1.5C2 0.671562 2.67156 0 3.5 0H12.5C13.3284 0 14 0.671562 14 1.5V16L8 12.5L2 16Z" fill="#4F4F4F" />
        </svg>
    )
}

export default BookmarkIcon