
type Props = {
    width?: string;
    height?: string;
}

const LegendIcon = ({ width = "12", height = "12" }: Props) => {
    return (
        <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1233_3124)">
                <path d="M13.5 2H4.5C3.675 2 3 2.675 3 3.5V15.5C3 16.325 3.675 17 4.5 17H13.5C14.325 17 15 16.325 15 15.5V3.5C15 2.675 14.325 2 13.5 2ZM6.75 3.5H8.25V7.25L7.5 6.6875L6.75 7.25V3.5ZM13.5 15.5H4.5V3.5H5.25V10.25L7.5 8.5625L9.75 10.25V3.5H13.5V15.5Z" fill="#4F4F4F" />
            </g>
            <defs>
                <clipPath id="clip0_1233_3124">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default LegendIcon