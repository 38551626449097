
type Props = {
    width?: string;
    height?: string;
}

const InfoIcon = ({ width = "12", height = "12" }: Props) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.4 0H1.6C0.72 0 0 0.72 0 1.6V16L3.2 12.8H14.4C15.28 12.8 16 12.08 16 11.2V1.6C16 0.72 15.28 0 14.4 0ZM14.4 11.2H3.2L1.6 12.8V1.6H14.4V11.2Z" fill="#4F4F4F" />
            <path d="M8.7925 6.75L10.3561 5.18641C10.548 4.99453 10.548 4.68344 10.3561 4.49141L10.0086 4.14391C9.81672 3.95203 9.50563 3.95203 9.31359 4.14391L7.75 5.7075L6.18641 4.14391C5.99453 3.95203 5.68344 3.95203 5.49141 4.14391L5.14391 4.49141C4.95203 4.68328 4.95203 4.99437 5.14391 5.18641L6.7075 6.75L5.14391 8.31359C4.95203 8.50547 4.95203 8.81656 5.14391 9.00859L5.49141 9.35609C5.68328 9.54797 5.99453 9.54797 6.18641 9.35609L7.75 7.7925L9.31359 9.35609C9.50547 9.54797 9.81672 9.54797 10.0086 9.35609L10.3561 9.00859C10.548 8.81672 10.548 8.50563 10.3561 8.31359L8.7925 6.75Z" fill="#4F4F4F" />
        </svg>
    )
}

export default InfoIcon