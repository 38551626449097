
type Props = {
    width?: string;
    height?: string;
}

const PointIcon = ({ width = "12", height = "12" }: Props) => {
    return (
        <svg width={width} height={height} color="#212529" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-352a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" />
        </svg>
    )
}

export default PointIcon