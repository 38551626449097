
type Props = {
    width?: string;
    height?: string;
}

const GeometryFilterIcon = ({ width = "17", height = "13" }: Props) => {
    return (
        <svg width={width} height={height} viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.2492 0H0.750872C0.0846841 0 -0.251472 0.808313 0.220559 1.28034L6 7.06066V13.5C6 13.7447 6.1194 13.9741 6.3199 14.1144L8.8199 15.8638C9.31312 16.2091 10 15.8592 10 15.2494V7.06066L15.7796 1.28034C16.2507 0.80925 15.9168 0 15.2492 0Z" fill="#4F4F4F" />
            <path d="M18.7861 9.378C18.6275 9.11667 18.4081 8.916 18.1281 8.776C17.8575 8.636 17.5355 8.566 17.1621 8.566C16.4715 8.566 15.9255 8.79 15.5241 9.238C15.1321 9.686 14.9361 10.288 14.9361 11.044C14.9361 11.8933 15.1461 12.542 15.5661 12.99C15.9955 13.4287 16.6161 13.648 17.4281 13.648C18.3895 13.648 19.0755 13.214 19.4861 12.346H16.7281V10.372H21.8521V13.032C21.6375 13.5547 21.3201 14.0447 20.9001 14.502C20.4895 14.9593 19.9668 15.3373 19.3321 15.636C18.6975 15.9253 17.9741 16.07 17.1621 16.07C16.1728 16.07 15.2955 15.86 14.5301 15.44C13.7741 15.0107 13.1861 14.418 12.7661 13.662C12.3555 12.8967 12.1501 12.024 12.1501 11.044C12.1501 10.0733 12.3555 9.21 12.7661 8.454C13.1861 7.68867 13.7741 7.096 14.5301 6.676C15.2861 6.24667 16.1588 6.032 17.1481 6.032C18.3895 6.032 19.4161 6.33067 20.2281 6.928C21.0401 7.52533 21.5441 8.342 21.7401 9.378H18.7861Z" fill="#4F4F4F" />
        </svg>
    )
}

export default GeometryFilterIcon