
type Props = {
    width?: string;
    height?: string;
}

const TableIcon = ({ width = "12", height = "12" }: Props) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5 1H1.5C0.671562 1 0 1.67156 0 2.5V13.5C0 14.3284 0.671562 15 1.5 15H14.5C15.3284 15 16 14.3284 16 13.5V2.5C16 1.67156 15.3284 1 14.5 1ZM7 13H2V10H7V13ZM7 8H2V5H7V8ZM14 13H9V10H14V13ZM14 8H9V5H14V8Z" fill="#4F4F4F" />
        </svg>
    )
}

export default TableIcon